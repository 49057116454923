import React from "react";

function Analytics() {
    return (
      <>
        <main>
          <p>
            Analytics page
          </p>
        </main>
      </>
    );
  }

  export default Analytics