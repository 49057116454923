
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PeopleIcon from '@mui/icons-material/People';
import HomeIcon from '@mui/icons-material/Home';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import InfoIcon from '@mui/icons-material/Info';
import Home from '../pages/home'
import SignIn from '../pages/signin'
import About from '../pages/about'
import Analytics from '../pages/analytics';
import Users from '../pages/users';
import React from 'react';

const AppRoutes = [
    {
        path:"/",
        sidebarName:"Home",
        component: <Home/>,
        icon: <HomeIcon />,
    },{
        path:"/users",
        sidebarName:"Users",
        component: <Users/>,
        icon: <PeopleIcon/>,
    },{
        path:"/analytics",
        sidebarName:"Analytics",
        component: <Analytics/>,
        icon: <AnalyticsIcon/>,
    },{
        path:"/signin",
        sidebarName:"Sign In",
        component: <SignIn/>,
        icon: <AppRegistrationIcon />,
    },{
        path:"/about",
        sidebarName:"About",
        component: <About/>,
        icon: <InfoIcon />,
    }

]

interface IRoute {
    path: string,
    sidebarName?:string,
    component?:Object,
    icon?:Object
}

export {AppRoutes, IRoute}