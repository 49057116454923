import { createSlice } from '@reduxjs/toolkit'

export const PlanSlice = createSlice({
  name: 'plan',
  initialState: {
    value: 'None',
  },
  reducers: {
    setPlan: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setPlan } = PlanSlice.actions

export default PlanSlice.reducer