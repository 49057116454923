import React from "react";

function Home() {
    return (
      <>
        <main>
          <h2>Welcome to the Heypal.io!</h2>
        </main>
      </>
    );
  }

  export default Home