import { configureStore } from '@reduxjs/toolkit'
import  PlanSlice  from './plan'
import  RoleSlice from './role'
import UserInfoSlice from './userInfo'


export default configureStore({
    reducer: {
        plan: PlanSlice,
        role: RoleSlice,
        userInfo: UserInfoSlice,
    },
  })
