import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {AppRoutes, IRoute} from './routes';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setIsLoggedIn } from './store/userInfo';

const drawerWidth = 240;
let nav_routes: Array<IRoute> = AppRoutes
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

function logout(){
  sessionStorage.clear()
  window.location.reload();

}

function useCheckLoggedIn(){
  let initOptions = sessionStorage.getItem('userInfo')
  let dispatch = useDispatch()
  console.log(initOptions)
  if (initOptions){
    console.log("calling init");
    console.log(JSON.parse(initOptions))
    try{
      (window as any).guidde.init(JSON.parse(initOptions))
    }catch(error){
      console.error(error);
    }
    dispatch(setIsLoggedIn(true))
  }
  return false
}
function SignInButton(props:any){
  let inf = sessionStorage.getItem('userInfo')
  if (inf){
    return (<span style={{float: "right", textAlign:"right" }}>
    <Button variant="outlined"  color="inherit" onClick={logout}>Logout</Button>
  </span>)
  }else{
    return (<span style={{float: "right", textAlign:"right"}}>
    <Button  component={Link} to="signin" variant="outlined"  color="inherit">Login</Button>
  </span>)
  }
}

function ResponsiveDrawer(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  useCheckLoggedIn()
  useEffect(() => {
    // Update the document title using the browser API
    // checkLoggedIn()
  });
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {nav_routes.map((route, index) => (
          <Link to={route.path} style={{ textDecoration: 'none' , color:'black'}}>
            <ListItem button key={index}>
              <ListItemIcon>
                {route.icon}
              </ListItemIcon>
              <ListItemText primary={route.sidebarName} />
            </ListItem>
           </Link>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" style={{ flex: 1 }}>
            Heypal.io
            <SignInButton isLoggedIn={useSelector((state:RootStateOrAny) => state.userInfo.value.isLoggedIn)}></SignInButton>
          </Typography>
         

        </Toolbar>
       
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;