import { createSlice } from '@reduxjs/toolkit'

export const RoleSlice = createSlice({
  name: 'role',
  initialState: {
    value: 'None',
  },
  reducers: {
    setRole: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setRole } = RoleSlice.actions

export default RoleSlice.reducer