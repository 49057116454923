import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import  {setRole} from '../../store/role'
import  {setPlan} from '../../store/plan'
import store from '../../store';
import { useNavigate } from "react-router-dom";
import { setIsLoggedIn } from '../../store/userInfo';

const theme = createTheme({
  palette: {
    mode: 'dark',
    secondary: {
      main: '#8d948e'
    }
  },
});

function getHashCode(s:any){
      var hash = 0;
      if (s.length == 0) return hash;
      for (let i = 0; i < s.length; i++) {
          let char = s.charCodeAt(i);
          hash = ((hash<<5)-hash)+char;
          hash = hash & hash; // Convert to 32bit integer
      }
      if (hash > 0)
        return hash;
      else
        return hash*-1
  }

function SelectRole() {
  const dispatch = useDispatch()

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setRole(event.target.value))
  };

  return (
    <FormControl  fullWidth sx={{  mt:3 }}>
    <InputLabel id="select-role-label">Select Role</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value= {useSelector((state:RootStateOrAny) => state.role.value)}
      label="role"
      onChange={handleChange}
    >
      <MenuItem value={'None'} >None</MenuItem>
      <MenuItem value={'Admin'} >Admin</MenuItem>
      <MenuItem value={'Viewer'} >Viewer</MenuItem>
    </Select>
    </FormControl>
  )
  
}
function SelectPlan() {
  const dispatch = useDispatch()

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setPlan(event.target.value))
  };

  return (
    <FormControl fullWidth sx={{  mt:3 }}>
      <InputLabel id="select-plan">Select Plan</InputLabel>

      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value= {useSelector((state:RootStateOrAny) => state.plan.value)}
        label="plan"
        onChange={handleChange}
      >
        <MenuItem value={'None'} >None</MenuItem>
        <MenuItem value={'Basic'} >Basic</MenuItem>
        <MenuItem value={'Pro'} >Pro</MenuItem>
      </Select>
    </FormControl>
  )
  
}


export default function SignIn() {
  let navigate = useNavigate();
  const dispatch = useDispatch()

  const HandleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    console.log("sending")
    // event.preventDefault()
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    let role = store.getState().role.value
    let plan = store.getState().plan.value
    let uid = getHashCode(data.get('email')) 
    let accountId = getHashCode(data.get('accountname'))

    const initOptions = ({
      // recommended (optional) user properties
      "userId": uid.toString(), // unique
      "userEmail": data.get('email'),
      "userName": data.get('fullname'),
  
      // recommended (optional) account properties
      "accountId": accountId.toString(), // unique
      "accountName": data.get('accountname'),
  
      // additional suggestions, you can add any additional key-values here,
      // as long as it's not one of the above reserved names.
      "userRole":role,
      "vesrion": "2.0",
      "languages": ["Spanish"],
      "accountIsPaying": "true",
      "accountType": "OEM",
      "accountPlan": plan
      });
      console.log(initOptions);
      sessionStorage.setItem('userInfo', JSON.stringify(initOptions))
      dispatch(setIsLoggedIn(true))
      navigate('/');
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={HandleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              defaultValue={"jhon@acme.com"}
              autoFocus
            />
             <TextField
              margin="normal"
              required
              fullWidth
              name="fullname"
              label="Full name"
              type="text"
              id="fullname"
              defaultValue={"john"}
              autoComplete="fullname"
            />
             <TextField
              margin="normal"
              required
              fullWidth
              name="accountname"
              label="Account name"
              type="text"
              id="accountname"
              autoComplete="accountname"
              defaultValue={"acme"}
            />
           <SelectRole></SelectRole>
           <SelectPlan></SelectPlan>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}