import { createSlice } from '@reduxjs/toolkit'

export const UserInfoSlice = createSlice({
  name: 'role',
  initialState: {
    value: {isLoggedIn:false,},
  },
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.value.isLoggedIn = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setIsLoggedIn } = UserInfoSlice.actions

export default UserInfoSlice.reducer