import './App.css';
import ResponsiveDrawer from "./Navigation"
import {AppRoutes, IRoute} from './routes';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

function App() {
  let nav_routes: Array<IRoute> = AppRoutes
  return (
    <div className="App">
      <header className="App-header">
      <div>
        </div>
        <ResponsiveDrawer></ResponsiveDrawer>
        <Routes>
          {nav_routes.map((route,index) => (
          <Route path={route.path} element={route.component} key={index}></Route>
          ))}
        </Routes>
      </header>
    </div>
  );
}

export default App;
