import React from "react";

function Users() {
    return (
      <div>
            users page
      </div>
    );
  }

  export default Users