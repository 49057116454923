import React from "react";

function About() {
    return (
      <>
        <main>
          <h2>About Page</h2>
          <p>
            That feels like an existential question, don't you
            think?
          </p>
        </main>
      </>
    );
  }

  export default About